
import Vue from 'vue';

interface Settings {
  enableBorder: boolean;
  enableHeadingUppercase: boolean;
}

interface Props {
  title: string | null;
  text: string | null;
  extra: string | null;
}

interface Computed {
  titleClass: Record<string, boolean>;
  headingClass: Record<string, boolean>;
  getSettings: Settings;
}

const defaultSettings = {
  enableBorder: false,
  enableHeadingUppercase: false,
};

export default Vue.extend<unknown, unknown, Computed, Props>({
  props: {
    title: {
      type: String,
      default: null,
      required: false,
    },
    text: {
      type: String,
      default: null,
      required: false,
    },
    extra: {
      type: String,
      default: null,
      required: false,
    },
  },
  computed: {
    getSettings() {
      const settings = this.$channelConfig('base').component.pageHeader;
      return { ...defaultSettings, ...settings };
    },
    titleClass() {
      return { 'page-title--with-border': this.getSettings.enableBorder };
    },
    headingClass() {
      return { 'page-title__heading--uppercase': this.getSettings.enableHeadingUppercase };
    },
  },
});
