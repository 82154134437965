
import Page from '@root/common/base/Page';

import { TagFetchState, TagFetchContext, TagFetchHandler, TagPath, TagRedirectStatus } from '@root/modules/tags/domain';
import { TagWithHeadlinesService } from '@root/modules/tags/services';

import ListSkeletonLoader from '@root/common/components/base/loader/ListSkeletonLoader.vue';
import HeadlineList from '@root/common/components/base/headline/HeadlineList.vue';
import PageHeader from '@root/common/components/base/PageHeader.vue';

import type { Data as Tag } from '@root/modules/tags/types/tags';
import type { QueryResult } from '@root/modules/headlines/types/headlines';
import { RefetchType } from '@root/common/types/domain';

interface Data {
  tag: Tag | null;
  headlines: QueryResult;
  settings: {
    publishDate: boolean;
    titleComment: boolean;
    author: boolean;
  };
}

interface Methods {
  changePage: () => void;
  handleRedirect: (tag: Tag) => void;
}

export default Page.extend<Data, Methods, unknown, unknown>({
  components: {
    HeadlineList,
    PageHeader,
    ListSkeletonLoader,
  },
  data() {
    return {
      tag: null,
      headlines: {
        items: [],
        pager: {
          offset: 0,
          limit: 0,
        },
      },
      settings: { publishDate: true, titleComment: false, author: true },
    };
  },
  watch: {
    'tag.id'(id: number) {
      if (id) {
        this.fixPageUrlParams('topic', { id: this.tag?.id, slug: this.tag?.slug });
      }
    },
  },
  async fetch() {
    const tagFetchState = new TagFetchState({ refetchType: this.refetch.activeType, tag: this.tag });
    const tagFetchContext = new TagFetchContext({ channel: this.$channelConfig('config'), route: this.$route });

    const tagFetchHandler = new TagFetchHandler(tagFetchState, tagFetchContext);

    // Fetch tag data
    this.startFetching(tagFetchHandler.state.fetchStatus);
    const [tagData, tagError] = await tagFetchHandler.handleFetch(new TagWithHeadlinesService());

    if (tagError) {
      // Try 1 refetch before rendering error page
      const willRefetch = this.handleFetchError(tagFetchHandler.state.fetchStatus, tagError);

      if (willRefetch) {
        return;
      }

      this.handlePageError(tagError);
      return;
    }

    const { tag, headlines } = tagData;

    this.handleRedirect(tag);

    this.tag = tag;
    this.headlines = headlines;

    // Handle successful fetch
    this.stopFetching(tagFetchHandler.state.fetchStatus);
  },
  methods: {
    changePage() {
      this.addRefetchToQuery(RefetchType.Force);
    },
    handleRedirect(tag: Tag) {
      const { domain, locale, lang } = this.$channelConfig('settings');
      const tagPath = new TagPath(tag);

      if (!tagPath.getState('isDataValid')) {
        this.$sentry.captureException(tagPath.getState('error'), {
          tags: { 'process.type': process.server ? 'server' : 'client' },
        });
        return;
      }

      const redirectPath = tagPath.createPath({
        domain,
        locale,
        lang,
        fakeDomainEnabled: this.$config.FAKE_DOMAIN_ENABLED,
        query: this.$nuxt.context.query,
        route: this.$nuxt.context.route,
      });

      if (tagPath.getState('redirectStatus') === TagRedirectStatus.Error) {
        this.$sentry.captureException(tagPath.getState('error'), {
          tags: { 'process.type': process.server ? 'server' : 'client' },
        });
        return;
      }

      if (tagPath.getState('redirectStatus') === TagRedirectStatus.Redirect) {
        this.handlePageRedirect(redirectPath);
      }
    },
  },
  head() {
    const meta = this.getPageMeta();
    if (this.tag?.name) {
      meta.setTitle({ title: this.tag.name });
    }

    if (this.tag?.description) {
      meta.description = this.tag.description;
      meta.ogDescription = this.tag.description;
    }

    return {
      title: meta.title,
      meta: meta.data,
      link: meta.link,
    };
  },
});
