import { FetchHandler } from '@root/common/domain';

import { TagWithHeadlinesService } from '@root/modules/tags/services';
import { TagFetchDataError } from '@root/modules/tags/errors';

import { isValidSignedInt32 } from '@root/common/utils/helpers';

import { TagFetchState } from './TagFetchState.domain';
import { TagFetchContext } from './TagFetchContext.domain';

import { FetchStatus, type FetchResponse } from '@root/common/types/domain';

import type { Data as Tag, HeadlinesData as Headlines } from '@root/modules/tags/types/tags';

type TagWithHeadlinesData = {
  tag: Tag;
  headlines: Headlines;
};

type TagWithHeadlinesFetchResponse = FetchResponse<TagWithHeadlinesData, TagFetchDataError>;

export class TagFetchHandler extends FetchHandler<TagFetchState, TagFetchContext> {
  constructor(FeedFetchState: TagFetchState, FeedFetchContext: TagFetchContext) {
    super(FeedFetchState, FeedFetchContext);
  }

  /**
   * Validate input before fetching data with service and return error if input is invalid
   */
  private handleInputError(id: number, slug: string): TagFetchDataError | null {
    let fetchInputError: null | TagFetchDataError = null;

    if (!slug && (Number.isNaN(Number(id)) || !isValidSignedInt32(Number(id)))) {
      this.state.fetchStatus = FetchStatus.Error;

      fetchInputError = new TagFetchDataError('Tag fetch error - invalid input', {
        state: this.state,
      });

      fetchInputError.tags.responseCode = 400;
      fetchInputError.contexts.data.refetchType = this.state.refetchType;
    }

    return fetchInputError;
  }

  public async handleFetch(tagWithHeadlinesService: TagWithHeadlinesService): TagWithHeadlinesFetchResponse {
    const inputError = this.handleInputError(Number(this.context.route.params.id), this.context.route.params.slug);

    // Handle invalid input error
    if (inputError) {
      return [null, inputError];
    }

    // Handle data fetching with service
    const [serviceResponse, serviceError] = await tagWithHeadlinesService.fetch(this.state, this.context);

    // Handle invalid response errors
    const noTags = !serviceResponse?.tags?.data?.length;

    if (serviceError || noTags) {
      this.state.fetchStatus = FetchStatus.Error;

      const fetchDataError = new TagFetchDataError('Tag fetch error - invalid data', {
        state: this.state,
      });

      if (serviceError) {
        fetchDataError.tags.responseCode = serviceError?.statusCode ?? fetchDataError.tags.responseCode;
      } else if (noTags) {
        fetchDataError.tags.responseCode = 404;
      }

      fetchDataError.contexts.data.refetchType = this.state.refetchType;

      this.state.fetchStatus = FetchStatus.Error;
      return [null, fetchDataError];
    }

    const tagResponse = serviceResponse.tags.data[0];
    const headlinesResponse = serviceResponse.headlines;

    // Handle successful data fetch
    this.state.fetchStatus = FetchStatus.Success;

    return [{ tag: tagResponse, headlines: headlinesResponse }, null];
  }
}
