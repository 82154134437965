import { QueryOptions } from '@apollo/client/core';
import { dataLoader, RedisDataLoaderOptions } from '@root/libs/redis/dataloader/dataLoader';

import Service from '@root/common/base/Service';

import { TagFetchState, TagFetchContext } from '@root/modules/tags/domain';

import { GetTagsWithHeadlines } from '@root/modules/tags/types/tags';
import getTagsWithHeadlines from '@root/modules/tags/graphql/getTagsWithHeadlines.graphql';

import { getRouteParams } from '@root/common/utils/url';
import { getPageOffset } from '@root/common/utils/getPageOffset';

import type { ServiceResponse } from '~/src/common/types/service';

type TagsWithHeadlinesFetchVariables = GetTagsWithHeadlines['variables'];
type TagsWithHeadlinesApiResponse = GetTagsWithHeadlines['data'];

export class TagWithHeadlinesService extends Service {
  constructor() {
    super({ serviceType: 'tags' });
  }

  /**
   * Get the service variables required for making a request
   */
  private getServiceVariables(state: TagFetchState, context: TagFetchContext): TagsWithHeadlinesFetchVariables {
    const params = getRouteParams({ id: context.route.params.id, slug: context.route.params.slug, desiredIdType: 'number' });

    const limit = 20;
    const page = context.route.query.page as string;
    const offset = getPageOffset(limit, page);
    const variables: TagsWithHeadlinesFetchVariables = {
      offset,
      limit,
      id: params.id,
      slug: params.slug,
      authorLanguage: context.channel.settings.lang.toUpperCase(),
      channelLanguage: context.channel.settings.lang.toUpperCase(),
    };

    return variables;
  }

  public async fetch(state: TagFetchState, context: TagFetchContext): ServiceResponse<TagsWithHeadlinesApiResponse> {
    const variables = this.getServiceVariables(state, context);

    const options = Object.assign({ query: getTagsWithHeadlines }, { variables });
    const dataLoaderOptions: RedisDataLoaderOptions = {
      remote: {
        keyPrefix: 'tag',
      },
    };

    // Create a request wrapper to handle API requests
    const requestWrapper = async (options: QueryOptions): Promise<TagsWithHeadlinesApiResponse | Error> => {
      const apiProvider = this.createProvider('GraphQL');
      apiProvider.selectAPI('content-api-v3').setLinkOptions({ useAutomaticPersistedQueries: true, useGETAutomaticPersistedQueries: true });
      const response = await apiProvider.query<TagsWithHeadlinesApiResponse>(options);

      this.throwGraphqlOrApolloErrorIfExists(response);

      return response.data;
    };

    if (variables.offset !== 0) {
      dataLoaderOptions.remote!.gracePeriodMs = 600000; // 10 minutes for pager
    }

    const redisDataLoader = dataLoader<QueryOptions, TagsWithHeadlinesApiResponse | Error>(requestWrapper, dataLoaderOptions);

    // Fetch data from redis or API
    const response: TagsWithHeadlinesApiResponse | Error = redisDataLoader
      ? await redisDataLoader.load(options)
      : await this.requestWrapperHandler<TagsWithHeadlinesApiResponse | Error>(() => requestWrapper(options));

    // Handle internal graphql errors
    if (response instanceof Error) {
      const errorData = this.generateErrorData(response);
      return [null, errorData];
    }

    return [response, null];
  }
}
