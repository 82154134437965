import { RootContext } from '@root/common/domain';
import type { RootContextInput } from '@root/common/types/domain';

type TagExposeContext = 'channel' | 'route';

export class TagFetchContext extends RootContext<TagExposeContext> {
  constructor(input: RootContextInput<TagExposeContext>) {
    super(input);
  }
}
