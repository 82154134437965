import { PagePathHandler, RedirectStatus, RedirectConditions } from '@root/common/base/PagePathHandler';
import matchChannel from '@root/common/utils/matchChannel';
import { buildAbsoluteRoute, buildQueryUrl } from '@root/router/utils/buildRoutePath';
import { Data as TagData } from '@root/modules/tags/types/tags';
// TODO(module scope): Remove channel dependency
import channels from '@root/modules/channel/config/channel.config';
import { Context } from '@nuxt/types';

export { RedirectStatus as TagRedirectStatus };

interface TagPathContext {
  domain: string;
  locale: string;
  lang: string;
  fakeDomainEnabled: boolean;
  query: Context['route']['query'];
  route: Context['route'];
}

type TagDataDeconstructed = Pick<TagData, 'id' | 'slug' | 'language'>;

/**
 * Create tag path for redirecting to the correct channel
 */
export class TagPath extends PagePathHandler {
  private _tagPathData: TagDataDeconstructed | null = null;

  constructor({ id, slug, language }: TagDataDeconstructed, options?: { redirectConditions: Partial<RedirectConditions> }) {
    super({
      name: 'TagPath',
      redirectConditions: {
        channel: true,
        id: true,
        slug: false,
        ...options?.redirectConditions,
      },
    });

    this.initializeAndValidate({ id, slug, language });
  }

  /**
   * Initialize and validate the tag data
   * Check if the tag data is valid and set the error if not
   */
  private initializeAndValidate({ id, slug, language }: TagDataDeconstructed): void {
    const isDataValid = Boolean(typeof id === 'number' && slug && language);

    this._isDataValid = isDataValid;
    this._tagPathData = isDataValid ? { id, slug, language } : null;

    if (!this._isDataValid) {
      this._error = new Error(`${this._name} initializeAndValidate: Invalid tag data - id: ${id}, slug: ${slug}, language: ${language}`);
    }
  }

  /**
   * Create new path for the tag redirect or return null if no redirect is needed
   */
  public createPath({ domain, locale, lang, fakeDomainEnabled, query, route }: TagPathContext): string | null {
    // Handle invalid data
    if (!this.isRedirectDataValid(this._tagPathData)) {
      return null;
    }

    // TODO (logic): tagChannel should not be undefined?
    const tagChannel = channels.find((channel) => channel.tag_channel && channel.locale === locale);

    if (!tagChannel?.domain) {
      this._redirectStatus = RedirectStatus.Error;
      return null;
    }

    const { id, slug, language } = this._tagPathData;
    const pathOptions = {
      type: 'topic',
      id,
      slug,
      channel: {
        language: language || lang,
        domain: tagChannel.domain,
      },
    };

    const sameChannel = matchChannel(domain, tagChannel.domain);

    // Check redirect conditions
    if (!this.shouldRedirect({ id, sameChannel, route })) {
      return null;
    }

    // Build the URL for redirect
    const url = buildAbsoluteRoute({ pathOptions, domain, fakeDomainEnabled });
    const queryUrl = buildQueryUrl(url, { query, route }) || null;

    // Handle invalid URL data
    // Skip redirect if the current URL is the same as the new URL - prevent infinite loop
    if (!this.isRedirectUrlValid(queryUrl, { domain, route })) {
      return null;
    }

    // Create the new path for redirection
    return this.handleRedirectPath(queryUrl);
  }
}
