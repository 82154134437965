import { FetchState } from '@root/common/domain';
import type { FetchState as IFetchState, FetchStateInput } from '@root/common/types/domain';
import type { Data as Tag } from '@root/modules/tags/types/tags';

interface ITagFetchState extends IFetchState {
  tag: Tag | null;
}

export class TagFetchState extends FetchState implements ITagFetchState {
  private _tag: ITagFetchState['tag'];

  constructor(input: FetchStateInput<ITagFetchState>) {
    super(input);
    this._tag = input.tag;
  }

  get tag(): ITagFetchState['tag'] {
    return this._tag;
  }

  set tag(tag: ITagFetchState['tag']) {
    this._tag = tag;
  }
}
