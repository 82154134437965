import { SentryError, type ErrorContext, type ErrorTags, type ServiceErrorTags } from '@root/common/base/SentryError';
import { TagFetchState } from '@root/modules/tags/domain';

import { TagFetchErrorReason } from './TagsFetchReason.error';

interface TagFetchErrorContext {
  cause: {
    reason: TagFetchErrorReason.NoData;
  };
  data: {
    tag: TagFetchState['tag'];
    refetchType: TagFetchState['refetchType'];
  };
}

export class TagFetchDataError extends SentryError<TagFetchErrorContext, ServiceErrorTags> {
  constructor(message: string, { state }: { state: TagFetchState }) {
    super(message);
    this.clientMessage = 'tags.error.not_found';

    const sentryContext: ErrorContext<TagFetchErrorContext> = {
      cause: {
        reason: TagFetchErrorReason.NoData,
      },
      data: {
        tag: state.tag,
        refetchType: state.refetchType,
      },
    };

    const sentryTags: ErrorTags<ServiceErrorTags> = {
      apiType: 'content',
      responseCode: 500,
    };

    this.setInitialContexts(sentryContext);
    this.setInitialTags(sentryTags);
  }
}
